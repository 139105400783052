<template>
  <div class="row bg-white">
    <div class="col-12">
      <c-search-box>
        <template slot="search">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 사업장 -->
            <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <!-- 기간 -->
            <c-datepicker
              name="measYear"
              type="date"
              :range="true"
              label="LBLPERIOD"
              v-model="year"
            ></c-datepicker>
          </div>
        </template>
      </c-search-box>
      <div class="row">
        <c-card :noHeader="true" class="cardClassDetailForm dashborad">
          <template slot="card-detail">
            <b-col sm="6" md="6" lg="6" xl="6" class="col-xxl-6">
              <!-- 측정위치 -->
              <c-select
                :comboItems="comboItems"
                type="search"
                itemText="codeName"
                itemValue="code"
                name="measQuarter"
                label="측정위치"
                v-model="searchParam.measQuarter"
              />
            </b-col>
            <div class="col-12">
              <apexchart height="450" :options="selfMeasure.chartOptions" :series="selfMeasure.series"></apexchart>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'first-line-status',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        measQuarter: null,
        measYear: '',
        startYmd: '',
        endYmd: '',
        measCompleteFlag: null,
      },
      selfMeasure: {
        series: [
          {
            name: 'COD',
            data: [12, 20, 30, 50, 10],
          },
          {
            name: 'BOD',
            data: [20, 30, 50, 10, 12],
          },
          {
            name: 'TOC',
            data: [30, 50, 10, 12, 20],
          },
          {
            name: 'T-N',
            data: [50, 10, 12, 20, 30],
          },
          {
            name: 'SS',
            data: [9, 10, 20, 30, 52],
          },
          {
            name: 'T-P',
            data: [10, 20, 10, 5, 9],
          },
          {
            name: 'Zn',
            data: [15, 12, 10, 40, 32],
          },
        ],
        chartOptions: {
          title: {
            text: '자가측정 추이분석'
          },
          // 그래프 설정
          chart: {
            type: 'line',
            height: 350,
            width: '95%',
            dropShadow: {
              enabled: true,
              color: '#000',
              top: 15,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: true,
            },
          },
          // 각 항목별 라벨
          dataLabels: {
            enabled: false,
          },
          // 선
          stroke: {
            width: 3,
            curve: 'smooth'
          },
          // 그래프 배경
          grid: {
            borderColor: '#e7e7e7',
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
          },
          // 점
          markers: {
            size: 4,
          },
          // x축
          xaxis: { 
            categories: ['2022-02','2022-03','2022-04','2022-05','2022-06',
            '2022-07'],
          },
          // y축
          yaxis: { 
            title: {
              text: ('(ppm)'),
              rotate: 0,
              offsetY: -150,
            }
          },
          // 색
          fill: { 
            opacity: 1,
          },
          // legend 위치
          legend: { 
            position: 'top',
            horizontalAlign: 'center',
          },
          // 툴팁: 커서 올리면 나오는 설명
          tooltip: { 
            y: {
              formatter: function (val) {
                return val + ('ppm');
              },
            }
          }
        }
      },
      comboItems: [
        { code: '1', codeName: '원폐수' },
        // { code: '2', codeName: '증류수' },
      ],

      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
    'count'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
    },
  }
};
</script>